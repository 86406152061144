<template>
	<div class="header">Choose Date</div>
	<div class="body flx jst-cntr">
		<div>
			<VueDatePicker v-model="date" :min-date="new Date()" inline auto-apply />
		</div>
	</div>
	<div class="footer">
		<button @click="$emit('close')">Close</button>
		<button v-if="date" @click="$emit('close', date)" class="bgGrdPos">Next</button>
	</div>
</template>

<script>
	import VueDatePicker from "@vuepic/vue-datepicker"
	import "@vuepic/vue-datepicker/dist/main.css"
	import { ref } from "vue"

	export default {
		name: "ChooseDate",
		components: { VueDatePicker },
		emits: ["close"],
		setup() {
			const date = ref(null)
			return {
				date
			}
		}
	}
</script>
